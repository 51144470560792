






























import { Component, Vue } from "vue-property-decorator";
import "@/types";
import members from "@/store/data/members";
import firebase from "@/plugins/firebase";

@Component({
  metaInfo: {
    title: "Giriş - Burs Başvurusu"
  }
})
export default class ScholarshipLoginView extends Vue {
  get member() {
    return this.$store.getters["scholarship/member"];
  }

  get memberSignedIn() {
    return this.$store.getters["scholarship/memberSignedIn"];
  }

  loginformIsValid = false;
  requiredRule = [(v: string) => !!v || "Zorunlu alan"];
  email = "";
  emailRules = [
    this.requiredRule[0],
    (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
  ];

  findMember(email: string): Member {
    return members.find(item => {
      if (item.email == email) {
        return item;
      }
    }) as Member;
  }

  async getMemberByEmail(email: string): Promise<Member | null> {
    let returnVal: Member | null = null;
    try {
      const getMemberByEmail = firebase
        .functions()
        .httpsCallable("getMemberByEmail");

      const res = await getMemberByEmail({ email: email });

      if (res.data.total > 0) {
        const member = res.data.user_list[0];
        returnVal = {
          id: member.id,
          fullName: member.name,
          email: member.email
        } as Member;
      }
    } catch (e) {
      console.error(e);
    }

    return returnVal;
  }

  async submitLoginForm() {
    const loginForm: HTMLFormElement = this.$refs.loginForm as HTMLFormElement;

    if (!loginForm.validate()) {
      return;
    }

    //const member = this.findMember(this.email);
    const member = await this.getMemberByEmail(this.email);

    if (member) {
      await firebase
        .firestore()
        .collection("/apps/kahev-org/memberSignInRequests")
        .add({
          email: member.email,
          fullName: member.fullName
        });

      alert(
        "Burs Başvuru Sistemi giriş linki e-posta adresinize gönderilmiştir."
      );
    } else {
      alert("Başvuru yapabilmek için KAHEV üyesi olmanız gerekmektedir.");
    }
  }

  async checkSignInId(id: string) {
    const docRef = await firebase
      .firestore()
      .doc(`/apps/kahev-org/memberSignInRequests/${id}`)
      .get();

    if (docRef.exists) {
      const email = docRef.data()?.email;

      // Oturum aç
      this.signIn(email);

      // Id'yi sil
      await firebase
        .firestore()
        .doc(`/apps/kahev-org/memberSignInRequests/${id}`)
        .delete();
    }
  }

  async signIn(email: string) {
    this.$store.dispatch(
      "scholarship/signIn",
      await this.getMemberByEmail(email)
    );
    this.$router.push({ name: "scholarship-select" });
  }

  signOut() {
    this.$store.dispatch("scholarship/signOut");
  }

  async mounted() {
    if (this.memberSignedIn) {
      this.$router.push({ name: "scholarship-select" });
    } else {
      const id = this.$route.params.id;
      if (id) {
        this.checkSignInId(id);
      }
    }
  }
}
